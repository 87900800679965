<template lang="html">
  <div class="mb-5">
    <!--  -->
    <!-- <section class="container">
      <div class="my-widget--slide w3-display-container">
        <img src="../../assets/logo-white.png" alt="" class="my-widget--placeholder w3-display-middle">
        <img :data-src="publication.image" alt="" class="my-widget--slide-content lazyload widget--slide-image-fixed" v-if="publication.image">
      </div>
    </section> -->

    <!--  -->
    <section class="container">
      <div class="row">
        <div class="col-12 col-md-8 pr-md-5">
          <!--  -->
          <div class="my-widget--slide w3-display-container  mb-5">
            <img
              src="../../assets/logo-white.png"
              alt=""
              class="my-widget--placeholder w3-display-middle"
            />
            <img
              :data-src="publication.image"
              alt=""
              class="my-widget--slide-content lazyload widget--slide-image-fixed"
              v-if="publication.image"
            />
          </div>
          <!--  -->
          <div class="border-bottom pb-3 mb-5 mt-0">
            <h3>
              <strong
                v-if="english && publication.title_en"
                v-html="publication.title_en"
              ></strong>
              <strong
                v-if="french && publication.title_fr"
                v-html="publication.title_fr"
              ></strong>
            </h3>

            <div class="caption widget--subtitle mt-2">
              <i class="fa fa-archive mr-2"></i>
              <span
                v-for="category in publication.categories"
                :key="category"
                class="text-capitalize mr-2 widget--little-separator"
              >
                <!-- <span v-if="english">{{getCategory(category.id).category_en}}</span> -->
                <!-- <span v-if="category.id">
              </span> -->
                <span v-if="french">{{ category }}</span>
                <span v-if="english">{{ category }}</span>
              </span>
              <i class="fa fa-bank ml-4 ml-3 mr-2"></i>
              <span
                v-for="office in publication.offices"
                :key="office"
                class="text-capitalize mr-2 widget--little-separator"
              >
                {{ office }}
              </span>
              <div class="mt-4" style="font-size:13px">
                <div class="_flex">
                  <!-- <span class="mr-4"><i class="fa fa-clock-o" aria-hidden="true"></i> 30 min</span> -->
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13px"
                      height="13px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-user mr-2"
                    >
                      <path
                        d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
                      ></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <span
                      v-for="author in publication.authors"
                      :key="author"
                      class="text-capitalize mr-2 widget--little-separator"
                      >{{ author }}</span
                    >
                    <!-- getAuthor(author) -->
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Content -->
          <p
            class="text-justify"
            v-html="publication.content_en"
            v-if="english && publication.content_en"
          ></p>
          <p
            class="text-justify"
            v-html="publication.content_fr"
            v-if="french && publication.content_fr"
          ></p>
        </div>
        <!-- Sidebar -->
        <div class="col-12 col-md-4">
          <Sidebar />
        </div>
      </div>
    </section>
    <!--  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { languageMixin } from "../../mixins/language";

export default {
  mixins: [languageMixin],
  props: ["id"],
  computed: {
    ...mapGetters(["newsAndPublications"]),
    publication() {
      return this.$store.getters.publication(this.id);
    }
  },
  created() {
    if (!this.newsAndPublications.length) {
      this.$store.dispatch("getNewsAndPublications");
    }
  }
};
</script>

<style lang="css"></style>
